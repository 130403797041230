exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-anti-snore-bite-explained-js": () => import("./../../../src/pages/anti-snore-bite-explained.js" /* webpackChunkName: "component---src-pages-anti-snore-bite-explained-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-demo-about-js": () => import("./../../../src/pages/demo/about.js" /* webpackChunkName: "component---src-pages-demo-about-js" */),
  "component---src-pages-demo-b-2-b-js": () => import("./../../../src/pages/demo/b2b.js" /* webpackChunkName: "component---src-pages-demo-b-2-b-js" */),
  "component---src-pages-demo-cart-js": () => import("./../../../src/pages/demo/cart.js" /* webpackChunkName: "component---src-pages-demo-cart-js" */),
  "component---src-pages-demo-checkout-js": () => import("./../../../src/pages/demo/checkout.js" /* webpackChunkName: "component---src-pages-demo-checkout-js" */),
  "component---src-pages-demo-co-working-js": () => import("./../../../src/pages/demo/co-working.js" /* webpackChunkName: "component---src-pages-demo-co-working-js" */),
  "component---src-pages-demo-consultation-js": () => import("./../../../src/pages/demo/consultation.js" /* webpackChunkName: "component---src-pages-demo-consultation-js" */),
  "component---src-pages-demo-contact-2-js": () => import("./../../../src/pages/demo/contact2.js" /* webpackChunkName: "component---src-pages-demo-contact-2-js" */),
  "component---src-pages-demo-contact-3-js": () => import("./../../../src/pages/demo/contact3.js" /* webpackChunkName: "component---src-pages-demo-contact-3-js" */),
  "component---src-pages-demo-ecommerce-js": () => import("./../../../src/pages/demo/ecommerce.js" /* webpackChunkName: "component---src-pages-demo-ecommerce-js" */),
  "component---src-pages-demo-job-details-js": () => import("./../../../src/pages/demo/job-details.js" /* webpackChunkName: "component---src-pages-demo-job-details-js" */),
  "component---src-pages-demo-job-directory-js": () => import("./../../../src/pages/demo/job-directory.js" /* webpackChunkName: "component---src-pages-demo-job-directory-js" */),
  "component---src-pages-demo-job-openings-js": () => import("./../../../src/pages/demo/job-openings.js" /* webpackChunkName: "component---src-pages-demo-job-openings-js" */),
  "component---src-pages-demo-mobile-app-js": () => import("./../../../src/pages/demo/mobile-app.js" /* webpackChunkName: "component---src-pages-demo-mobile-app-js" */),
  "component---src-pages-demo-pricing-1-js": () => import("./../../../src/pages/demo/pricing1.js" /* webpackChunkName: "component---src-pages-demo-pricing-1-js" */),
  "component---src-pages-demo-pricing-2-js": () => import("./../../../src/pages/demo/pricing2.js" /* webpackChunkName: "component---src-pages-demo-pricing-2-js" */),
  "component---src-pages-demo-pricing-3-js": () => import("./../../../src/pages/demo/pricing3.js" /* webpackChunkName: "component---src-pages-demo-pricing-3-js" */),
  "component---src-pages-demo-product-details-js": () => import("./../../../src/pages/demo/product-details.js" /* webpackChunkName: "component---src-pages-demo-product-details-js" */),
  "component---src-pages-demo-product-js": () => import("./../../../src/pages/demo/product.js" /* webpackChunkName: "component---src-pages-demo-product-js" */),
  "component---src-pages-demo-reset-pass-js": () => import("./../../../src/pages/demo/reset-pass.js" /* webpackChunkName: "component---src-pages-demo-reset-pass-js" */),
  "component---src-pages-demo-saas-subscription-js": () => import("./../../../src/pages/demo/saas-subscription.js" /* webpackChunkName: "component---src-pages-demo-saas-subscription-js" */),
  "component---src-pages-demo-signin-js": () => import("./../../../src/pages/demo/signin.js" /* webpackChunkName: "component---src-pages-demo-signin-js" */),
  "component---src-pages-demo-signup-js": () => import("./../../../src/pages/demo/signup.js" /* webpackChunkName: "component---src-pages-demo-signup-js" */),
  "component---src-pages-demo-telemedicine-js": () => import("./../../../src/pages/demo/telemedicine.js" /* webpackChunkName: "component---src-pages-demo-telemedicine-js" */),
  "component---src-pages-demo-terms-conditions-js": () => import("./../../../src/pages/demo/terms-conditions.js" /* webpackChunkName: "component---src-pages-demo-terms-conditions-js" */),
  "component---src-pages-demo-video-conference-js": () => import("./../../../src/pages/demo/video-conference.js" /* webpackChunkName: "component---src-pages-demo-video-conference-js" */),
  "component---src-pages-demo-web-application-js": () => import("./../../../src/pages/demo/web-application.js" /* webpackChunkName: "component---src-pages-demo-web-application-js" */),
  "component---src-pages-error-js": () => import("./../../../src/pages/error.js" /* webpackChunkName: "component---src-pages-error-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-news-how-to-clean-bite-js": () => import("./../../../src/pages/news/how-to-clean-bite.js" /* webpackChunkName: "component---src-pages-news-how-to-clean-bite-js" */),
  "component---src-pages-pricing-js": () => import("./../../../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-thank-you-js": () => import("./../../../src/pages/thank-you.js" /* webpackChunkName: "component---src-pages-thank-you-js" */),
  "component---src-pages-why-do-we-snore-js": () => import("./../../../src/pages/why-do-we-snore.js" /* webpackChunkName: "component---src-pages-why-do-we-snore-js" */)
}

